<template>
  <div>
    <section class="consult">
      <div class="consult-container">
        <p data-aos="fade-up" class="content phone-none">這是一件不在你預期內的狀況，所以有緊張、焦慮、害怕、疑惑、不確定…的感覺是很正常的。<br>
           而我們很關心你現在的處境及心理狀態，<br>所以你可以透過下面諮詢服務，更進一步分享你的感受及狀態。<br>
           這件事本就不該是你一個人面對！<br>如果你願意，你可以在這網站中找到相關的資源、資訊或是專業社工後續陪伴你一起度過。
        </p>
        <p data-aos="fade-up" class="content pc-none">這件事本就不該是你一個人面對！如果你願意，你可以在這網站中找到相關的資源、資訊或是專業社
           工後續陪伴你一起度過。
        </p>
        <h2 data-aos="fade-up">-我想要諮詢-</h2>
        <p data-aos="fade-up" class="consult-subTitle">請選擇您需要的諮詢管道</p>
        <!-- 內容 -->
        <ul class="d-flex justify-content-between flex-wrap" data-aos="fade-up">
          <!--<li class="left-item">-->
          <li>
            <a href="tel:0800257085" class="consult-box">
              <img src="../assets/image/front/consult_icon_mobile.svg">
              <p>電話服務</p>
            </a>
            <p class="consult-content">免付費全國未成年懷孕諮詢專線：0800-25-7085 <br>服務時間為週一到週五09:00～18:00 <br>將有專人為您服務。</p>
          </li>
          <!--<li>
            <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=637rlwfd" target="_blank" class="consult-box">
              <img src="../assets/image/front/consult_icon_talk.svg">
              <p>線上聊聊</p>
            </a>
            <p class="consult-content">歡迎您登入會員使用線上聊聊與我們聯絡， <br>我們將提供您個人專屬的各項專業諮詢。</p>
          </li>-->
        </ul>
        <hr data-aos="fade-up">
        <div data-aos="fade-up">
          <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
          <a class="goBack" href="#">返回首頁</a>
        </div>
      </div>
    </section>
  </div>
</template>
